<template>
  <TrainerEditTemplate>

    <div class="row">
      <div class="col-8">
        <FveSelectAjaxV2Place
          label="Добавить площадку"
          name="place"
          v-model="place"
        />
      </div>
      <div class="col-4">
        <div class="btn-gp" style="padding-top: 24px;">
          <b-button size="lg" variant="danger" @click="addTrainerPlace">Добавить</b-button>
          <!--<b-button size="lg" variant="link" @click="$emit('cancel')">Отмена</b-button>-->
        </div>
      </div>
    </div>

    <div class="legend">Клубы</div>
    <div class="row">
      <template v-for="(item, index) in placeList">
        <div class="col-4 mb-md" :key="index" v-if="item.club">
          <CardClub
            :satus="item.is_active"
            :name="item.name"
            :club="item.club_name"
            :text="item.address"
            :tags="item.tags.map( (obj) => obj.tag)"
          >
            <template slot="control">
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12.81 13.83" v-bind:svg-inline="''" @click="unlinkPlace(item)" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.38 13.83H3.76A2.22 2.22 0 011.5 11.7C1.29 9.8.93 5.33.93 5.29a.75.75 0 111.5-.12s.36 4.49.57 6.36c.07.67.46.77.8.77h5.19a.75.75 0 00.84-.78c.21-1.86.57-6.31.57-6.36a.76.76 0 01.81-.69.75.75 0 01.69.81s-.37 4.52-.58 6.4A2.24 2.24 0 019 13.81zM12.06 3.83H.75a.75.75 0 010-1.5h11.31a.75.75 0 010 1.5z"/><path d="M9.88 3.83a1.85 1.85 0 01-1.81-1.49l-.17-.81a.2.2 0 00-.09 0H5a.1.1 0 00-.1.08l-.15.76a1.85 1.85 0 01-1.82 1.46.75.75 0 010-1.5.35.35 0 00.34-.33l.16-.81A1.62 1.62 0 015 0h2.81a1.6 1.6 0 011.55 1.19l.18.81a.35.35 0 00.34.28.75.75 0 110 1.5z"/></svg>
            </template>
          </CardClub>
        </div>
      </template>
    </div>

    <div class="legend">Другие площадки</div>
    <div class="row">
      <template v-for="(item, index) in placeList">
        <div class="col-4 mb-md" :key="index"  v-if="!item.club">
          <CardClub
            :satus="item.is_active"
            :name="item.name"
            :text="item.address"
          >
            <template slot="control">
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12.81 13.83" v-bind:svg-inline="''" @click="unlinkPlace(item)" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.38 13.83H3.76A2.22 2.22 0 011.5 11.7C1.29 9.8.93 5.33.93 5.29a.75.75 0 111.5-.12s.36 4.49.57 6.36c.07.67.46.77.8.77h5.19a.75.75 0 00.84-.78c.21-1.86.57-6.31.57-6.36a.76.76 0 01.81-.69.75.75 0 01.69.81s-.37 4.52-.58 6.4A2.24 2.24 0 019 13.81zM12.06 3.83H.75a.75.75 0 010-1.5h11.31a.75.75 0 010 1.5z"/><path d="M9.88 3.83a1.85 1.85 0 01-1.81-1.49l-.17-.81a.2.2 0 00-.09 0H5a.1.1 0 00-.1.08l-.15.76a1.85 1.85 0 01-1.82 1.46.75.75 0 010-1.5.35.35 0 00.34-.33l.16-.81A1.62 1.62 0 015 0h2.81a1.6 1.6 0 011.55 1.19l.18.81a.35.35 0 00.34.28.75.75 0 110 1.5z"/></svg>
            </template>
          </CardClub>
        </div>
      </template>
    </div>

  </TrainerEditTemplate>
</template>

<script>

import TrainerEditTemplate from "@page/Trainer/_component/TrainerEditTemplate";
import CardClub from "@component/Module/CardClub";
import FveSelectAjaxV2Place from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Place";

export default {
  name: "TrainerEditPlace",
  components: {
    TrainerEditTemplate,
    CardClub,
    FveSelectAjaxV2Place,
  },
  props: {
    trainerId: [String, Number]
  },
  data(){
    return this.getDefaultData();
  },
  methods:{
    getDefaultData() {
      return {
        place: {},
        placeList: [],
      };
    },

    load(){
      RequestManager.Trainer.getTrainerById({
        id: this.trainerId
      }).then((response) => {
        this.placeList = response.trainer_courts;
      });
    },
    reload(){
      Object.assign(this, this.getDefaultData());
      this.load();
    },

    addTrainerPlace(){
      RequestManager.Trainer.addPlace({
        trainerId: this.trainerId,
        placeId: this.place.id,
      }).then(this.reload);
    },
    unlinkPlace(placeObj) {
      RequestManager.Trainer.removePlace({
        trainerId: this.trainerId,
        placeId: placeObj.id,
      }).then(this.reload);
    }
  },
  mounted() {
    this.load();
  }
};
</script>

<style lang="scss" scoped>

</style>
